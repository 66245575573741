<template>
    <div class="default-headerBar">
        <div class="Logo">
            <img class="img" src="@/assets/logo.png" alt=""> 
            助店通电脑端
        </div>
        <div class="tool-bar">
            <div class="service">专属客服：{{kfInfo.kfmobile}}({{kfInfo.kfname}})</div>
            <div class="tb-wrap">
                <div class="profile-wrap">
                    <div class="img-wrap">
                        <img v-if="head != null" :src="head" alt="">
                    </div>
                    <div class="companyName">
                        <div class="shop-name" @click="clickShopShow">
                            {{username}}
                            ({{name}})
                        </div>
                        <i class="iconfont el-icon-arrow-down"></i>
                        <div class="shop-wrap" :class="isShow?'active':''">
                            <div class="item"
                                v-for="(item,index) in userInfo.user_list" :key="index" >
                                <div  @click="clickShop(item)" v-if="item.endtime_state == 1">{{item.brand_name}}</div>
                                <div v-else :class="item.endtime_state != 1?'isEndTime':''">{{item.brand_name}}(已过期)</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="logout" @click="handleLogouts">
                    <div class="off">退出登录 <i class="el-icon-switch-button"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data() {
        return {
            userInfo: {},
            username: '',
            name: '',
            head: '',
            kfInfo: '',
            isShow: false,
            endTime: false
        }
    },
    mounted(){
        let token = localStorage.getItem('token');
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userInfo.user_list.forEach(v => {
            if(v.token_pc == token){
                this.name = v.brand_name;
                this.username = v.username;
                this.head = v.head;
                this.getCustomerService();
                // this.getPermission();
            }
        });
    },
    methods: {
        clickShopShow(){
            this.isShow = !this.isShow
        },
        getPermission(){
            common.connect("/distributorpcv1/user/permission",{},(res)=>{
                console.log(res);
                localStorage.setItem("permission",JSON.stringify(res.data));
                window.location.reload()
                this.$forceUpdate();
            });
        },
        getCustomerService(){
            common.connect("/distributorpcv1/shop/customerService",{},(res)=>{
                this.kfInfo = res.data
            });
        },
        clickShop(item){
             this.$confirm('是否要切换当前账号?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                localStorage.setItem('token', item.token_pc);
                localStorage.setItem('ep_id', item.ep_id);
                this.getPermission();
                // window.location.reload()
            });
        },
        handleLogouts(){
            this.$confirm('将退出当前账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/distributorpcv1/auth/logout",{},(res)=>{
                    localStorage.clear()
                    //要使用location强制跳转
                    location.href="/";   
                });
            });
        }
    },
    computed: {
        adAccountName(){
            return this.$store.state.adAccountName
        }
    }
}
</script>

<style lang="scss">
.default-headerBar{
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    background-color: $headerBgColor;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    .Logo{
        width: 250px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        background-color: #0B1529; 
        color: $logoColor;
        .img{
            width: 35px;
            margin-right: 10px;
        }
    }
    .tool-bar{
        width: calc(100% - 250px);
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .service{
            margin-left: 20px;
            font-size: 15px;
        }
        .tb-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .profile-wrap{
            display: flex;
            align-items: center;
            .img-wrap{
                width: 23px;
                height: 23px;
                margin-right: 10px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            
            .companyName{
                cursor: pointer;
                color: #000;
                position: relative;
                display: flex;
                align-items: center;
                .iconfont{
                    font-size: 16px;
                    color: #000;
                }
                .active{
                    display: block !important;
                }
                .shop-wrap{
                    display: none;
                    position: absolute;
                    top: 30px;
                    background: #fff;
                    padding: 5px 15px;
                    border-radius: 5px;
                    width: 180px;
                    box-shadow: 0 0 2px 2px rgba(100, 100, 100, 0.1);
                    .item{
                        display: flex;
                        flex-direction: column;
                        font-size: 16px;
                        color: #333;
                        cursor: pointer;
                        padding: 5px 0;
                    }
                    .item:hover{
                        color: #409eff;
                    }
                    .isEndTime{
                        color: #999;
                    }
                }
            }
        }
        .logout{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px;
            font-size: 15px;
            cursor: pointer;
            .off{
                font-size: 15px;
                padding: 2px;
                color: #606266;
            }
        }
    }
}
</style>