<template>
    <div class="default-menuBar">
        <el-menu :default-active="curretMenuIndex" router :unique-opened="true" @select="selectIndex">
            <template v-for="(item) in menuList">
                <el-submenu :index="item.index" v-if='item.sbuMenu' :key="item.id">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span>{{ item.title }}</span>
                    </template>
                    <div v-for="(subItem, subindex) in item.subs" :key="subindex">
                        <el-menu-item-group v-if="subItem.sbuMenu">
                            <el-menu-item :key="subindex" :index="subItem.index" style="padding-left:55px">
                                {{ subItem.title }}
                            </el-menu-item>
                        </el-menu-item-group>
                    </div>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    mounted() {
        let ep_id = localStorage.getItem('ep_id')
        let permission = JSON.parse(localStorage.getItem('permission'));
        this.menuList = this.$menu.storeMenu;
        this.menuList.forEach(v => {
          console.log(v.title)
            //一级菜单
            if (v.title == '来访管理') {
                if (permission.visitor_is_open) {
                    v.sbuMenu = true
                } else {
                    v.sbuMenu = false
                }
            }
            if (v.title == '灯塔计划') {//依诺
                v.sbuMenu = false
                if (permission.is_open_lighthouse && (ep_id==1 || ep_id==12|| ep_id==9 )) {
                    v.sbuMenu = true
                }
            }
            if (v.title == '客户分析') {//陶元帅、威尔顿
                v.sbuMenu = false
                if (permission.is_open_lighthouse && (ep_id==1 || ep_id==48 || ep_id==52 )) {
                    v.sbuMenu = true
                }
            }
            if (v.title == '星光计划') {//大将军
                v.sbuMenu = false
                if (permission.is_open_lighthouse && (ep_id==1 || ep_id==36)) {
                    v.sbuMenu = true
                }
            }
            if (v.title == '星火计划') {//大将军
                v.sbuMenu = false
                if (permission.is_open_lighthouse && (ep_id==51 || ep_id==53)) {
                    v.sbuMenu = true
                }
            }
           

            v.subs.forEach(v1 => {
                //二级菜单
                if (v1.title == '全景素材' && ep_id == 46) {
                    v1.sbuMenu = true
                }
                if (v1.title == '来访录入') {
                    if (permission.visitor_is_pc_write) {
                        v1.sbuMenu = true
                    } else {
                        v1.sbuMenu = false
                    }
                }
                if (v1.title == '门店产品') {
                    v1.sbuMenu = false
                    if (permission.is_open_add_shopgoods) {
                        v1.sbuMenu = true
                    }
                }

            })

        })
    },
    computed: {
        curretMenuIndex() {
            return this.$store.state.curretMenuIndex
        }
    },
    data() {
        return {
            menuList: [],
            defaultActive: ""
        }
    },
    methods: {
        ...mapMutations(['setCurretMenuIndex']),
        selectIndex(index) {
            this.setCurretMenuIndex(index)
        },
    }
}
</script>

<style lang="scss">
.default-menuBar {
    width: 250px;
    height: 100%;
    box-sizing: border-box;
    // box-shadow: 0px 5px 10px 0px #ECEEF1; 
    // border-right: 1px solid #ccc;
    // border: 1px solid red;
    // background-color: $sideBgColor;
    z-index: 98;
    // background-color: #fff;
    background-color: #0B1529;

    .profile-wrap {
        height: 220px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        .profile {
            box-sizing: border-box;

            img {
                border: 1px solid #E8E8E8;
                border-radius: 50px;
                padding: 3px 3px;
                box-sizing: border-box;
                width: 80px;
                height: 80px;
                box-shadow: 0px 0px 10px #ccc;
            }

            .wechat-info {
                margin-top: 8px;
                text-align: center;

                .name {
                    font-weight: 600;
                    color: #2C2C2C;
                }

                .welcome {
                    margin-top: 5px;
                    font-size: 12px;
                    color: #9B9B9B;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 85%;
            background-color: #E0E0E0;
            bottom: 0;
            left: 20px;
        }
    }

    .el-menu {
        width: 100%;
        height: calc(100% - 220px);
        border-right: none;
        // border: 1px solid red;
        text-align: left;
        // padding-left: 10px;
        box-sizing: border-box;
        background-color: #0B1529;

        .iconfont {
            margin-right: 15px;
            font-size: 18px;
            font-weight: 500;
            color: #fff;
        }

        .el-menu-item {
            // color: #fff;
            // font-weight: 600;
            color: #909399;
            font-size: 15px;
        }

        .el-submenu {
            .el-submenu__title {
                font-size: 15px;
                color: #909399;

            }

            .el-menu-item {
                font-size: 14px;
                color: #909399;

            }

            // .el-menu-item-group{
            // }    
        }

        .el-menu-item:hover {
            background-color: #182848;
            color: #909399;

            .iconfont {
                color: #909399;
            }
        }

        .el-submenu__title:hover {
            background-color: #182848;
            color: #909399;

            .iconfont {
                color: #909399;
            }
        }

        .el-menu-item:focus {
            color: #fff;
            background-color: #182848;
        }

        .el-menu-item.is-active {
            color: #fff;
            background-color: #182848;

            i {
                color: #fff;
            }
        }

        .el-submenu.is-active>.el-submenu__title {
            color: #fff;

            i {
                color: #fff;
            }
        }
    }
}
</style>